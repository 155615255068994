import { create } from "zustand";
import { persist } from "zustand/middleware";
import { ICustomerConfiguration } from "@/interfaces/ICustomerConfiguration";
import { IFacets } from "@/interfaces/IFacets";
import { IRanges, IRanges1 } from "@/interfaces/IRanges";
import { IEquipmentCategory } from "@/interfaces/IEquipment";

/*****STATES*****/
interface StoreState {
  vat: number;
  customerConfiguration: ICustomerConfiguration | null;
  selectedCarLink: string | null;
  facets: IFacets | null;
  rg: IRanges | null;
  bearerToken: string | null;
  equipments: IEquipmentCategory[] | null;
  listSelectedSort: string;
  listSortOrder: boolean;
  listPageSizeNew: number;
  listView: number;
  lastSearchParameters: string;
}
const initializeState = (): StoreState => ({
  vat: 19,
  customerConfiguration: null,
  selectedCarLink: null,
  facets: null,
  rg: null,
  bearerToken: null,
  equipments: null,
  listSelectedSort: "new",
  listSortOrder: true,
  listPageSizeNew: 12, //TODO: new name to reset. 'new' can be removed later
  listView: 1,
  lastSearchParameters: ""
});

/*****ACTIONS*****/
interface StoreActions {
  setVat: (value: number) => void;
  setCustomerConfiguration: (value: ICustomerConfiguration) => void;
  setSelectedCarLink: (value: string) => void;
  setFacets: (value: IFacets) => void;
  setRg: (value: IRanges1) => void;
  setBearerToken: (value: string) => void;
  setEquipments: (value: IEquipmentCategory[]) => void;
  setListSelectedSort: (value: string) => void;
  setListSortOrder: (value: boolean) => void;
  setListPageSizeNew: (value: number) => void;
  setListView: (value: number) => void;
  setLastSearchParameters: (value: string) => void;
}
const initializeActions = (set: any): StoreActions => ({
  setVat: (value) => set({ vat: value }),
  setCustomerConfiguration: (value) => {
    console.log("customerConfiguration:", value);
    set({ customerConfiguration: value });
  },
  setSelectedCarLink: (value) => set({ selectedCarLink: value }),
  setFacets: (value) => {
    console.log("facets:", value);
    set({ facets: value });
  },
  setRg: (value) => set({ rg: value }),
  setBearerToken: (value) => set({ bearerToken: value }),
  setEquipments: (value) => set({ equipments: value }),
  setListSelectedSort: (value) => set({ listSelectedSort: value }),
  setListSortOrder: (value) => set({ listSortOrder: value }),
  setListPageSizeNew: (value) => set({ listPageSizeNew: value }),
  setListView: (value) => set({ listView: value }),
  setLastSearchParameters: (value) => set({ lastSearchParameters: value })
});

const useStore = create<StoreState & StoreActions>()(
  persist(
    (set) => ({
      ...initializeState(),
      ...initializeActions(set)
    }),
    {
      name: "dotzilla-mpi-storage"
    }
  )
);

export default useStore;
