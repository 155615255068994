import { useEffect } from "react";
import { ICustomerConfiguration } from "@/interfaces/ICustomerConfiguration";
import { Box } from "@mui/material";
import { styled } from "@mui/system";
import { loadPriceRating } from "./AutoUncleWidgets";

const CustomButton = styled(Box)({
  "& .auws-button": {
    width: "100%",
    backgroundColor: "primary.main", // Der theme wird automatisch verwendet
    color: "white !important",
    fontSize: "h2.fontSize", // Diese Eigenschaften werden automatisch aus dem theme geholt
    "&:hover": {
      backgroundColor: "primary.dark"
    }
  }
});

interface IAuEmbedConfig {
  id: string;
  departmentId?: string;
  onFormOpen?: (details: any) => void;
  onCustomerDetailsSent?: (details: any) => void;
  customData?: object;
  data?: any;
  noDelayedCtaPage?: boolean;
  pageType?: string;
  previewUrl?: string;
}

interface IAuEmbed {
  pushedUrls: string[];
  pushUrl: (url: string) => void;
  config: IAuEmbedConfig;
}

declare global {
  interface Window {
    AuEmbed: IAuEmbed;
  }
}

interface IAutoUncleInit {
  customerConfig: ICustomerConfiguration;
}

const AutoUncleInit: React.FC<IAutoUncleInit> = ({ customerConfig }) => {
  //TODO
  return;
  const autouncleEnabled = true; // TODO
  const id = "hSK2wjKB0T"; // TODO
  // const id = "HSq7b1ATbT"; // TODO
  const departmentId = ""; // TODO

  useEffect(() => {
    if (autouncleEnabled) {
      console.log(customerConfig);
      if (document.getElementById("autouncle-script")) {
        // script is already loaded
        return;
      }

      // configs
      window.AuEmbed = window.AuEmbed || {
        pushedUrls: [],
        pushUrl(url: string) {
          this.pushedUrls.push(url);
        },
        config: {
          id: id,
          departmentId: departmentId,
          onFormOpen(details) {
            console.log("details", details);
          },
          onCustomerDetailsSent(details) {
            console.log("details", details);
          },
          customData: {},
          data: {
            stockId: null
          },
          noDelayedCtaPage: null // true/false
        }
      };

      // check for viewport meta tag
      if (!document.head.querySelector("meta[name=viewport]")) {
        console.warn('The AutoUncle Universal Script cannot detect any <meta name="viewport"> tag. Ensure that the script is added after this tag!');
      }

      // create data for the script
      const scriptData = {
        depId: window.AuEmbed.config.departmentId,
        device: window.matchMedia("only screen and (max-width: 760px)").matches ? "mobile" : "desktop",
        pageType: window.AuEmbed.config.pageType,
        url: window.AuEmbed.config.previewUrl || document.location.href
      };

      // create and insert the script element
      const scriptElement = document.createElement("script");
      scriptElement.async = true;
      scriptElement.src = `https://www.autouncle.de/de/customers/${encodeURIComponent(window.AuEmbed.config.id)}/website_script.js?d=${encodeURIComponent(JSON.stringify(scriptData))}`;
      scriptElement.id = "autouncle-script";

      document.head.appendChild(scriptElement);

      //add widgets
      loadPriceRating();
    }
  }, [autouncleEnabled]);

  return null;
};

export default AutoUncleInit;

export function setStockId(stockId: number) {
  window.AuEmbed.config.data.stockId = stockId;
}

export const ElementCalculator: React.FC = () => {
  return <CustomButton className="au-widget-trade-in" />;
};

export const ElementTestDrive: React.FC = () => {
  return <CustomButton className="au-book-test-drive-cta" />;
};
