import React, { useEffect } from "react";

interface MatomoProps {
  url: string | null;
  siteId: string | null;
}

const clearMatomoCookies = () => {
  const cookies = document.cookie.split(";");
  for (let cookie of cookies) {
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
    if (name.trim().startsWith("_pk_") || name.trim().startsWith("MATOMO_SESSID")) {
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    }
  }
};

const Matomo: React.FC<MatomoProps> = ({ url, siteId }) => {
  useEffect(() => {
    if (!url || !siteId) {
      clearMatomoCookies();
      return;
    }

    const scriptId = "matomo-script";

    // check if script already exists
    const existingScript = document.getElementById(scriptId);
    if (existingScript) return;

    const script = document.createElement("script");
    script.id = scriptId;
    script.src = `${url}/matomo.js`;
    script.async = true;
    document.head.appendChild(script);

    const scriptContent = document.createElement("script");
    scriptContent.id = `${scriptId}-content`;
    scriptContent.innerHTML = `
      var _paq = window._paq = window._paq || [];
      _paq.push(['trackPageView']);
      _paq.push(['enableLinkTracking']);
      (function() {
        var u="${url}/";
        _paq.push(['setTrackerUrl', u+'matomo.php']);
        _paq.push(['setSiteId', '${siteId}']);
        var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
        g.type='text/javascript'; g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
      })();
    `;
    document.head.appendChild(scriptContent);

    return () => {
      const scriptToRemove = document.getElementById(scriptId);
      const scriptContentToRemove = document.getElementById(`${scriptId}-content`);
      if (scriptToRemove) document.head.removeChild(scriptToRemove);
      if (scriptContentToRemove) document.head.removeChild(scriptContentToRemove);
      clearMatomoCookies();
    };
  }, [url, siteId]);

  return null;
};

export default Matomo;
