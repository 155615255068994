import { useEffect, useState } from "react";
import { deepmerge } from "@mui/utils";
import { createTheme, ThemeProvider, responsiveFontSizes } from "@mui/material/styles";
import "@fontsource/open-sans/300.css";
import "@fontsource/open-sans/400.css";
import "@fontsource/open-sans/500.css";
import "@fontsource/open-sans/600.css";
import "@fontsource/open-sans/700.css";
import MainContainer from "./MainContainer";
import { ICustomerConfiguration } from "@/interfaces/ICustomerConfiguration";
import { Alert, Container, ScopedCssBaseline, Snackbar } from "@mui/material";
import { MenuProvider } from "./contexts/menuContext";
import { PaginationProvider } from "./contexts/paginationContext";
import { convertHexToRGBA } from "./tools/tools";
import { defaultConfigTheme, customStyles } from "./defaultConfigTheme";
import { getBackendApi } from "./tools/backendApis";
import useStore from "@store";
import { GlobalStyles } from "@mui/system";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import deLocales from "./locales/de-DE";
import enLocales from "./locales/en-US";
import bnLocales from "./locales/bn-BD";
import { Translations } from "./locales/translations";
import deLocalesCustomer from "./locales/customer/de-DE";
import enLocalesCustomer from "./locales/customer/en-US";
import { TranslationsCustomer } from "./locales/customer/translationsCustomer";
import Footer from "./Footer";
import { useLocation } from "react-router-dom";
import ExternalScriptContainer from "./components/Externals/ExternalScriptContainer";

function initTranslation() {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources: {
        "de-DE": { common: deLocales, customer: deLocalesCustomer },
        "en-US": { common: enLocales, customer: enLocalesCustomer },
        "bn-BD": { common: bnLocales }
      },
      lng: "de-DE",
      fallbackLng: "en-US",
      ns: ["common", "customer"],
      defaultNS: "common",
      interpolation: {
        escapeValue: false
      }
    });
}

declare module "i18next" {
  interface CustomTypeOptions {
    defaultNS: "common";
    resources: {
      common: Translations;
      customer: TranslationsCustomer;
    };
  }
}

const App = () => {
  const { customerConfiguration, setCustomerConfiguration, setEquipments } = useStore();
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [error, setError] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [theme, setTheme] = useState(createTheme(defaultConfigTheme));
  const location = useLocation();

  useEffect(() => {
    const apiUrl = getBackendApi("/init");
    fetch(apiUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Fehler beim Laden der Konfiguration: ${response.status} Bitte wenden Sie sich an den Support`);
        }
        return response.json();
      })
      .then((data) => {
        setEquipments(data.ausstattungen);
        setThemeConfig(data.config);
        setCustomerConfiguration(data.config);
      })
      .catch((error) => {
        if (!error.response) {
          setError("Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später nochmal.");
        } else {
          setError(`Fehler bei der Anfrage: ${error.message}`);
        }
        setOpenSnackbar(true); // Öffnen der Snackbar bei einem Fehler
      })
      .finally(() => {
        setIsDataLoaded(true);
      });
  }, [useStore]);

  // initialisieren der Übersetzungen
  useEffect(() => {
    initTranslation();
  }, []);

  const setThemeConfig = (config: ICustomerConfiguration) => {
    const theme1 = createTheme(config && config.styles.mui ? deepmerge(defaultConfigTheme, config.styles.mui || {}) : defaultConfigTheme);

    const theme2 = {
      palette: {
        action: {
          active: theme1.palette.grey[500],
          hover: convertHexToRGBA(theme1.palette.primary.main, 0.1),
          selected: convertHexToRGBA(theme1.palette.primary.main, 0.1),
          disabled: theme1.palette.grey[500],
          disabledBackground: theme1.palette.grey[200]
        }
      },
      components: {
        MuiTab: {
          styleOverrides: {
            root: {
              color: theme1.palette.secondary.main,
              "&.Mui-selected": {
                backgroundColor: theme1.palette.primary.main
              }
            }
          }
        }
      }
    };

    setTheme(responsiveFontSizes(createTheme(deepmerge(theme1, theme2))));

    // set html root font size (default: 16px)
    document.documentElement.style.fontSize = config?.styles?.custom?.fontSize || "16px";
  };

  // scroll top after each route change
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // hide custom elements after each route change
  useEffect(() => {
    if (location.pathname !== "/") {
      const hideElements = customerConfiguration?.styles?.hidden;
      hideElements?.forEach((selector) => {
        const elements = document.querySelectorAll(selector);
        elements.forEach((element) => {
          (element as HTMLElement).style.display = "none";
        });
      });
    }
  }, [location.pathname]);

  const sxContainer = customerConfiguration?.styles?.custom?.sxContainer || {};

  return (
    <ScopedCssBaseline>
      <MenuProvider>
        <PaginationProvider>
          <ThemeProvider theme={theme}>
            <Container id="mpi-container" sx={{ padding: { xs: 0 }, textAlign: "left", ...sxContainer }}>
              {error && (
                <Snackbar
                  open={openSnackbar}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  onClose={() => setOpenSnackbar(false)}
                  style={{
                    position: "relative",
                    top: 30,
                    left: "50%",
                    marginBottom: 60,
                    transform: "translateX(-50%)"
                  }}
                >
                  <Alert onClose={() => setOpenSnackbar(false)} severity="error">
                    {error}
                  </Alert>
                </Snackbar>
              )}
              {isDataLoaded && customerConfiguration && <MainContainer customerConfig={customerConfiguration} />}
              {isDataLoaded && customerConfiguration && <ExternalScriptContainer customerConfig={customerConfiguration} />}
              <Footer />
            </Container>
          </ThemeProvider>
          <GlobalStyles styles={customStyles} />
        </PaginationProvider>
      </MenuProvider>
    </ScopedCssBaseline>
  );
};

export default App;
