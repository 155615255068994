import React from "react";
import GoogleAnalytics from "./Statistics/GoogleAnalytics";
import { ICustomerConfiguration } from "@interface/ICustomerConfiguration";
import AutoUncleInit from "./AutoUncle/AutoUncleInit";
import Matomo from "./Statistics/Matomo";
import Etracker from "./Statistics/Etracker";

interface IExternalScriptContainer {
  customerConfig: ICustomerConfiguration;
}

const ExternalScriptContainer: React.FC<IExternalScriptContainer> = ({ customerConfig }) => {
  return (
    <>
      <GoogleAnalytics trackingId={customerConfig?.api?.tracking?.google?.key} />
      <Matomo url={customerConfig?.api?.tracking?.matomo?.url} siteId={customerConfig?.api?.tracking?.matomo?.siteId} />
      <Etracker accountId={customerConfig?.api?.tracking?.etracker?.accountId} />
      <AutoUncleInit customerConfig={customerConfig} />
    </>
  );
};
export default ExternalScriptContainer;
