import { createContext, useState, ReactNode } from "react";

type MenuContextType = {
  isMenuOpen: boolean;
  toggleMenu: () => void;
  setViewOpen: (isViewOpen: number) => void;
  isViewOpen: number;
};

const defaultMenuContext: MenuContextType = {
  isMenuOpen: true,
  toggleMenu: () => {},
  setViewOpen: () => {},
  isViewOpen: 1
};

export const MenuContext = createContext<MenuContextType>(defaultMenuContext);

export const MenuProvider = ({ children }: { children: ReactNode }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isViewOpen, setViewOpen] = useState(1);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <MenuContext.Provider
      value={{
        isMenuOpen,
        toggleMenu,
        setViewOpen,
        isViewOpen
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};
