import { createContext, useState, useContext, ReactNode } from "react";

type PaginationContextType = {
  page: number;
  perPage: number;
  setPage: (page: number) => void;
  setPerPage: (perPage: number) => void;
};

const defaultPaginationContext: PaginationContextType = {
  page: 1,
  perPage: 12,
  setPage: () => {},
  setPerPage: () => {}
};

// Erstellen des Kontexts
export const PaginationContext = createContext<PaginationContextType>(defaultPaginationContext);

// Erstellen des Providers
export const PaginationProvider = ({ children }: { children: ReactNode }) => {
  const [page, setPage] = useState(defaultPaginationContext.page);
  const [perPage, setPerPage] = useState(defaultPaginationContext.perPage);

  return <PaginationContext.Provider value={{ page, perPage, setPage, setPerPage }}>{children}</PaginationContext.Provider>;
};

// Erstellen einer benutzerdefinierten Hook für den einfachen Zugriff auf den Kontext
export const usePagination = () => useContext(PaginationContext);
