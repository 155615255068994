import { ThemeOptions } from "@mui/material/styles";

//colors
const colorCommon = "rgba(0, 0, 0, 0.7)";
const colorPrimary = "#FF0000";
const colorSecondary = "#000000";
const colorError = "#d32f2f";
const colorWarning = "#ffa726";
const colorInfo = "#FF00FF";
const colorSuccess = "#2e7d32";

//textSizes
const fontSizeH1 = "26px";
const fontSizeH2 = "20px";
const fontSizeH3 = "18px";
const fontSizeH4 = "16px";
const fontSizeH5 = "14px";
const fontSizeH6 = "12px";
const fontSizeSubtitle1 = "19px";
const fontSizeSubtitle2 = "11px";
const fontSizeBody1 = "16px";
const fontSizeBody2 = "14px";
const fontSizeButton = "16px";
const fontSizeOverline = "10px";
const fontSizeCaption = "12px";

// cards
const cardBorder = "1px solid";
const cardBorderColor = "#eee";
const cardBorderRadius = "4px";
const cardShadow = "0px 4px 12px rgba(0, 0, 0, 0.2)";
const cardShadowHover = "0px 8px 22px rgba(0, 0, 0, 0.3)";

// buttons
const buttonRadius = "2px";

//others
const colorChipAvailableGood = "#66BB6A";
const colorChipAvailableBad = "#b7b9b2";

export const defaultConfigTheme: ThemeOptions = {
  spacing: 8,
  palette: {
    common: {
      black: colorCommon
    },
    primary: {
      main: colorPrimary
    },
    secondary: {
      main: colorSecondary
    },
    error: {
      main: colorError
    },
    warning: {
      main: colorWarning
    },
    info: {
      main: colorInfo
    },
    success: {
      main: colorSuccess
    }
  },

  typography: {
    fontSize: 14,
    htmlFontSize: 16,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,

    h1: {
      fontSize: fontSizeH1,
      color: "#000000",
      lineHeight: "1.1",
      contrastText: "#fff"
    },
    h2: {
      fontSize: fontSizeH2,
      fontWeight: "fontWeightRegular",
      color: "#000000"
    },
    h3: {
      fontSize: fontSizeH3,
      fontWeight: "fontWeightMedium",
      color: "#000000",
      textTransform: "uppercase"
    },
    h4: {
      fontSize: fontSizeH4
    },
    h5: {
      fontSize: fontSizeH5
    },
    h6: {
      fontSize: fontSizeH6
    },
    subtitle1: {
      fontSize: fontSizeSubtitle1
    },
    subtitle2: {
      fontSize: fontSizeSubtitle2
    },
    body1: {
      fontSize: fontSizeBody1
    },
    body2: {
      fontSize: fontSizeBody2
    },
    button: {
      fontSize: fontSizeButton,
      fontWeight: "bold"
    },
    overline: {
      fontSize: fontSizeOverline
    },
    caption: {
      fontSize: fontSizeCaption
    }
  },

  // das sind die DefaultWerte
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },

  transitions: {
    create: () => "none"
  },

  components: {
    MuiFormControlLabel: {
      styleOverrides: {}
    },

    MuiPagination: {
      styleOverrides: {}
    },

    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: buttonRadius
        }
      }
    },

    MuiPaper: {
      defaultProps: {
        elevation: 0
      },
      styleOverrides: {
        root: {
          backgroundImage: "none",
          padding: 0,
          boxShadow: "none",
          square: true
        },
        outlined: {
          border: cardBorder,
          borderColor: cardBorderColor,
          borderRadius: cardBorderRadius,
          ":hover": {
            borderColor: "#aaa",
            boxShadow: cardShadowHover
          },
          padding: 0,
          boxShadow: "none",
          square: true
        }
      }
    },

    MuiCard: {
      styleOverrides: {
        root: {
          border: cardBorder,
          borderColor: cardBorderColor,
          borderRadius: cardBorderRadius,
          padding: 0,
          boxShadow: cardShadow
        }
      }
    },

    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: { variant: "h3" },
        subheaderTypographyProps: { variant: "body1" }
      },
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          borderRadius: cardBorderRadius,
          padding: 8
        }
      }
    },

    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 8
        }
      }
    },

    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: 0,
          "& Button": {
            color: "#fff !important"
          }
        }
      }
    },

    MuiMenu: {
      styleOverrides: {
        paper: {
          border: "1px solid black",
          zIndex: 10000
        }
      }
    },

    MuiPopover: {
      styleOverrides: {
        root: {
          zIndex: 10000
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: "secondary.main",
          "&.Mui-selected": {
            color: "white !important",
            backgroundColor: "primary.main"
          }
        }
      }
    },
    MuiSlider: {
      styleOverrides: {
        thumb: {
          backgroundColor: "#fff",
          border: "1px solid currentColor",
          "&:hover": {
            boxShadow: "0 0 0 8px rgba(58, 133, 137, 0.16)"
          }
        }
      }
    },

    MuiChip: {
      styleOverrides: {
        root: {
          paddingBottom: "1px",
          backgroundColor: colorChipAvailableBad,
          color: "white",
          fontSize: "12px",
          fontWeight: "bold",
          "&.MuiChip-available": {
            backgroundColor: colorChipAvailableGood,
            fontSize: "14px"
          }
        }
      }
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(55,55,155,0.01)"
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          pointerEvents: "none"
        },
        arrow: {
          pointerEvents: "none"
        }
      }
    }
  }
};

const htmlElements = ["div", "p", "h2", "h3", "h4", "h5", "h6", "ul", "ol", "li", "table", "tr", "td", "th", "header", "footer", "section", "article", "nav", "aside", "main", "figure", "figcaption", "form", "fieldset", "legend", "label", "input", "textarea", "button", "select", "option", "pre", "code", "blockquote", "hr"];

export const customStyles = htmlElements.reduce(
  (styles, element) => {
    styles[element] = { padding: 0, margin: 0 };
    return styles;
  },
  {} as { [key: string]: { padding: number; margin: number } }
);
