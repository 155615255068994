export function getBackend(path: string) {
  return import.meta.env.VITE_APP_API_URL + path;
}

export function getBackendApi(path: string) {
  return getBackend("/proxy/v1") + path;
}

export function getBackendApi2(path: string) {
  return getBackend("/proxy/v2") + path;
}

export function getBackendApi3(path: string) {
  return getBackend("/proxy/v3") + path;
}
